const Footer = () => {
    const footer = <div className="footerComponent">
        <h4>
            Made with ❤️ in San Francisco
        </h4>
    </div>

    return footer
}

export default Footer;